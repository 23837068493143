<template>
    <div>
        <!-- <h2>Job Family</h2>
         <b-button variant="outline-primary" v-b-modal.AddJobFamily>
            <b-icon icon="plus-circle-fill"  scale="1" variant="success"></b-icon>
            Add Job Family
        </b-button> -->
          <v-btn   color="primary" class="ma-2 white--text"  @click="showAddDialog">
           Add Job Family <v-icon right dark> mdi-plus</v-icon>
        </v-btn>

         <v-container color="primary">
              <v-card
                    icon="clipboard-text"
                    title="Job Family"
                    class="px-5 py-10">

                     <v-card-title>
                    <v-row>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details>
                        </v-text-field>
                    </v-row>
                    <v-row>
                        <v-progress-linear
                            :active="loading"
                            color="green"
                            :indeterminate="loading"
                            absolute
                            bottom
                            height="15">
                            Loading...
                        </v-progress-linear>`
                    </v-row>
                </v-card-title>

                     <v-data-table
                         dense
                        :headers="fields"
                        :items="items"
                         :search="search"
                        class="elevation-1">
                         <template  v-slot:[`header.englishName`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template  v-slot:[`header.arabicName`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template  v-slot:[`header.Action`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                            <template v-slot:top>
                                <v-dialog
                                    v-model="Editdialog"
                                    max-width="800px">
                                     <v-card>
                                             <v-card-title>
                                                <span class="text-h5">Edit Job Family</span>
                                            </v-card-title>
                                            <v-card-text>
                                                 <v-form ref="form" v-model="valid" lazy-validation>
                                                    <v-container>
                                                         <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.englishName"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="English Name">
                                                                </v-text-field>
                                                            </v-col>
                                                         </v-row>
                                                         <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.arabicName"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="Arabic Name">
                                                                </v-text-field>
                                                            </v-col>
                                                         </v-row>
                                                    </v-container>
                                                 </v-form>
                                            </v-card-text>
                                             <v-card-actions>
                                                <v-spacer></v-spacer>
                                                 <v-btn color="blue darken-1" text @click="close"> Cancel</v-btn>
                                                 <v-btn color="blue darken-1" :disabled="!valid" text @click="validate" >Save</v-btn>
                                             </v-card-actions>
                                        </v-card>
                                </v-dialog>
                            </template>
                            <template v-slot:[`item.Action`]="{ item }">
                                    <v-icon small class="mr-2"  @click="editItem(item)" >
                                        mdi-pencil
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="red" v-if="item.status==0" >
                                        mdi-brightness-1
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="green" v-if="item.status==1" >
                                        mdi-brightness-1
                                    </v-icon>
                                    <v-icon small class="mr-2"  @click="approveDeleteConfirm(item)" color="red"  >
                                        mdi-delete-empty
                                    </v-icon>
                                </template>
                     </v-data-table>
              </v-card>

                <v-dialog
        v-model="addJobFamilyDialog"
        max-width="800px">
            <v-card>
                    <v-card-title>
                    <span class="text-h5">Add Job Family</span>
                </v-card-title>
                <v-card-text>
                        <v-form ref="Addform" v-model="valid" lazy-validation>
                        <v-container>
                                <v-row>
                                <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                        v-model="editedItem.englishName"
                                        :rules="[rules.required]"
                                        required
                                        label="English Name">
                                    </v-text-field>
                                </v-col>
                                </v-row>
                                <v-row>
                                <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                        v-model="editedItem.arabicName"
                                        :rules="[rules.required]"
                                        required
                                        label="Arabic Name">
                                    </v-text-field>
                                </v-col>
                                </v-row>
                        </v-container>
                        </v-form>
                </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close"> Cancel</v-btn>
                        <v-btn color="blue darken-1" :disabled="!valid" text @click="validateNew" >Save</v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>


        <div class="text-center">
                <v-snackbar v-model="snackbar" timeout="3000">
                    {{ alertMsg }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="green"
                            text
                            v-bind="attrs"
                            @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
            </v-snackbar>
        </div>

         <v-dialog v-model="approveReqDialog" max-width="500px">
          <v-card
          :loading = loading loading-text="Loading... Please wait">
            <v-card-title class="text-h3">ِAre you sure you want to Activate/DeActivate Job Family?  </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="updateJobFamilyStatus">Yes</v-btn>
              <v-btn color="green darken-1" text @click="closeApprove">No</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
         <v-dialog v-model="existingIndustryType" max-width="500px">
          <v-card>
            <v-card-title class="text-h3">Can't update status,Job Family has jobs, please deactivate them first and then try again  </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="existingIndustryType = false">Close</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </v-container>
     <v-dialog v-model="confirmDeleteDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">Are you sure ? </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteJobFamily">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="confirmDeleteDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
           <!-- <b-table
        striped hover
        :items="items"
        :fields="fields"
        ref="JobFamiliesList"
        v-if="items.length > 0"
        >
        <template #cell(Action)="row">
             <b-button-toolbar>
                <b-button-group class="mr-1">
                        <b-button  squared variant="primary" title="Edit" @click="editJobFamily(row.item,row.index)">
                            <b-icon icon="pen" aria-hidden="true"></b-icon>
                        </b-button>
                        <b-button  squared :variant="getVariant(row.item,1)" title="Activate" @click="updateJobFamilyStatus(row.item,row.index,1)">
                            <b-icon icon="person-check-fill" aria-hidden="true"></b-icon>
                        </b-button>
                        <b-button  squared :variant="getVariant(row.item,0)" title="DeActivate" @click="updateJobFamilyStatus(row.item,row.index,0)">
                            <b-icon icon="person-x-fill" aria-hidden="true"></b-icon>
                        </b-button>
                </b-button-group>
            </b-button-toolbar>
        </template>
        </b-table>

         <div v-if="items.length ==0">
            <center>
                <h2>No Items</h2>
            </center>
        </div> -->


         <!-- <b-modal id="AddJobFamily" title="Add Job Family" hide-footer hide-backdrop @show="onReset()">
            <form ref="form" @submit.stop.prevent="addJobFamily">
                 <b-form-group
                label="Id"
                label-for="Id" >
                    <b-form-input
                        type="text"
                        id="txtId"
                        name="txtId"
                        placeholder="Id"
                        v-model="$v.form.id.$model"
                        :state="validateState('id')"
                        aria-describedby="id-feedback"
                        disabled
                        >
                    </b-form-input>
                </b-form-group>
              <b-form-group
                label="English Name"
                label-for="englishName" >
                    <b-form-input
                        type="text"
                        id="txtEnglishName"
                        name="txtEnglishName"
                        placeholder="English Name"
                        v-model="$v.form.englishName.$model"
                        :state="validateState('englishName')"
                        aria-describedby="englishName-feedback"
                        >
                    </b-form-input>
                    <b-form-invalid-feedback id="englishName-feedback">
                        This is a required field and must be at least 3 characters.
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                label="Arabic Name"
                label-for="arabicName" >
                    <b-form-input
                        type="text"
                        id="txtArabicName"
                        name="txtArabicName"
                        placeholder="Arabic Name"
                        v-model="$v.form.arabicName.$model"
                        :state="validateState('arabicName')"
                        aria-describedby="arabicName-feedbackk"
                        >
                    </b-form-input>
                    <b-form-invalid-feedback id="arabicName-feedback">
                        This is a required field and must be at least 3 characters.
                    </b-form-invalid-feedback>
                </b-form-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button class="ml-2" @click="onReset()">Reset</b-button>
            </form>
         </b-modal>

          <b-modal id="EditJobFamily" title="Edit Job Family" hide-footer hide-backdrop>
            <form ref="form" @submit.stop.prevent="updateJobFamily">
              <b-form-group
                label="English Name"
                label-for="englishName" >
                    <b-form-input
                        type="text"
                        id="txtEnglishName"
                        name="txtEnglishName"
                        placeholder="English Name"
                        v-model="$v.form.englishName.$model"
                        :state="validateState('englishName')"
                        aria-describedby="englishName-feedback"
                        >
                    </b-form-input>
                    <b-form-invalid-feedback id="englishName-feedback">
                        This is a required field and must be at least 3 characters.
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                label="Arabic Name"
                label-for="arabicName" >
                    <b-form-input
                        type="text"
                        id="txtArabicName"
                        name="txtArabicName"
                        placeholder="Arabic Name"
                        v-model="$v.form.arabicName.$model"
                        :state="validateState('arabicName')"
                        aria-describedby="arabicName-feedbackk"
                        >
                    </b-form-input>
                    <b-form-invalid-feedback id="arabicName-feedback">
                        This is a required field and must be at least 3 characters.
                    </b-form-invalid-feedback>
                </b-form-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button class="ml-2" @click="onReset()">Reset</b-button>
            </form>
         </b-modal> -->
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    data(){
        return{
            confirmDeleteDialog:false,
            search:'',
            loading:false,
            addJobFamilyDialog:false,
            Editdialog: false,
            valid: false,
            rules: {
                required: value => !!value || 'Required Field',
            },
             editedItem: {
                id:-1,
                arabicName: '',
                englishName:'',
                status:-1,
            },
            editedIndex:-1,
            snackbar:false,
            approveReqDialog:false,
            existingIndustryType:false,

            fields: [
                 {value: 'englishName', text: this.$t("economicSector.grid.englishName") },
                {value: 'arabicName', text: this.$t("economicSector.grid.arabicName") },
                {value: 'Action', text: this.$t("economicSector.grid.Action") }],
            items: [],
            form: {
                englishName: null,
                arabicName:null,
                id:-1
            },
            currentEditedSector:null
        }
    },
    validations: {
        form: {
            englishName: {
                required,
                minLength: minLength(3)
            },
            arabicName: {
                required,
                minLength: minLength(3)
            },
            id:{

            }
        }
    },
    methods:{
        approveDeleteConfirm(item){
            if(Boolean(item.hasChildren) == false){
                 this.editedItem = item;
                this.editedIndex = this.items.indexOf(item)
                this.confirmDeleteDialog = true
            }
            else{
                this.alertMsg = "Job family cannot be deleted, it has Jobs assigned to it"
                this.snackbar = true;
            }
        },
         editItem (item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.Editdialog = true
        },
         approveRequestConfirm(item){
            this.editedItem = item;
            this.editedIndex = this.items.indexOf(item)

            this.approveReqDialog = true;
        },
         validate () {
            if(this.$refs.form.validate())
            {
                this.updateJobFamily();
            }
         },
         validateNew () {
            if(this.$refs.Addform.validate())
            {
                this.addJobFamily();
            }
         },
         close () {
            this.Editdialog = false
            this.addJobFamilyDialog = false;
            this.$nextTick(() => {
                this.clear();
            })
        },
        showAddDialog(){
            this.clear();
            this.addJobFamilyDialog = true
        },
        clear(){
                this.editedIndex = -1
                this.editedItem.id = -1;
                this.editedItem.arabicName = '';
                this.editedItem.englishName = '';
        },
        updateJobFamilyStatus(){
            let activate = this.editedItem.status == 1? 0:1;
            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/JobFamily/status?Id=${this.editedItem.id}&Status=${activate}`,
                }).then(()=>{
                    this.approveReqDialog = false;
                    this.$nextTick(() => {
                                    this.items[this.editedIndex].status == 1 ?
                                    this.items[this.editedIndex].status = 0: this.items[this.editedIndex].status = 1
                                    this.alertMsg = "Economic Sector updated successfully"
                                    this.snackbar = true;
                             })
                });

        },
        getVariant(item,activateFamily){
            if(Boolean(activateFamily) == true){
                if(item.status == 1)
                    return 'primary'
                else
                    return 'secondary'
            }
            else{
                if(item.status == 1)
                    return 'secondary'
                else
                    return 'primary'
            }

        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        editJobFamily(item,index)
        {
            console.log(item);
            this.form.englishName = item.englishName;
            this.form.arabicName = item.arabicName;
            this.form.id = item.id;
            this.$bvModal.show('EditJobFamily');
            this.currentEditedSector = index;
        },
        deleteJobFamily(){
            this.$axios({
                    method: 'delete',
                    url: `${this.$baseURL}/JobFamily`,
                    data: {Id:this.editedItem.id}
                    }).then(()=>{
                       this.items .splice(this.editedIndex , 1);
                        this.confirmDeleteDialog = false;
                    });

        },
        updateJobFamily(){
             this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/JobFamily`,
                    data: {
                        EnglishName: this.editedItem.englishName,
                        ArabicName: this.editedItem.arabicName,
                        id:this.editedItem.id
                    }
                }).then((response)=>{
                     this.approveReqDialog = false;
                    if(response.data == true)
                    {
                        this.$nextTick(() => {
                                    Object.assign(this.items[this.editedIndex], this.editedItem);
                                    this.close()
                                    this.alertMsg = "Job Family updated successfully"
                                    this.snackbar = true;
                             })
                    }
                });
        },
        addJobFamily(){

            this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/JobFamily`,
                    data: {
                        EnglishName: this.editedItem.englishName,
                        ArabicName: this.editedItem.arabicName
                    }
                }).then((response)=>{

                    this.$nextTick(() => {
                        this.$bvModal.hide('AddJobFamily')
                    });
                     this.$nextTick(() => {
                                this.items = response.data;
                                this.close()
                                this.alertMsg = "Job Family added successfully"
                                this.snackbar = true;
                            });

                    this.items = response.data;

                });
        },
        onReset(){
             this.form ={
                englishName: null,
                arabicName:null,
            };
             this.$nextTick(() => {
                this.$v.$reset();
            });
        },
    },
     mounted(){
        this.loading = true;
        this.$axios({
                method: 'get',
                url: `${this.$baseURL}/JobFamily`,
            })
            .then((response)=>{
                this.items = response.data;
                this.loading = false;
            });
    }
}
</script>

<style>

</style>